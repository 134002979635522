import React, {useState, useEffect} from "react";
import Seo from "../components/seo"
import Layout, {BlackLink, SegmentLink, StyledLink} from "../components/layout";
import {graphql, Link, navigate} from "gatsby";
import * as R from 'ramda';
import {BlackLine, DottedLine} from "../components/line";
import {FiShoppingCart, FiX} from 'react-icons/fi';
import {useCart} from '../cart';

import {CompactButton} from "../components/button";
import {useIsMobile} from "../utils";

const Cart = () => {
    //
    // useEffect(() => {
    //     clearCart();
    // }, []);

    return (
        <div>
            <Seo title="下訂成功"/>
            <div
                style={{
                    display: "flex",
                    maxWidth: 800,
                    margin: '0px auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div>
                    下訂成功
                </div>
                <div>
                    我們會盡快為您準備
                </div>
                <div style={{ height: 24}} />
                <CompactButton
                    onClick={() => {
                        navigate('/shop');
                    }
                    }
                >
                    繼續購物
                </CompactButton>
            </div>
        </div>
    );
};

export default Cart;
